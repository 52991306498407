(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var navBarCollapsed = true;
var mainNavbar = document.querySelector("#main-navbar");
var mainNavbarToggle = document.querySelector("#main-navbar-toggle");
var dropdownMenuItem = document.querySelector(".dropdown");
var buttonMapsCookieConsent = document.querySelector("#button-maps-cookie-consent");
var alertDiv = document.querySelector(".alert");

dropdownMenuItem.addEventListener("mouseover", function () {
    dropdownMenuItem.classList.add("open");
});
dropdownMenuItem.addEventListener("mouseout", function () {
    dropdownMenuItem.classList.remove("open");
});

mainNavbarToggle.addEventListener("click", toggleNavbar);

if (buttonMapsCookieConsent) {
    buttonMapsCookieConsent.addEventListener("click", function () {
        var parentElement = this.parentElement;
        var mapsSrcUrl = "https://www.google.com/maps/embed/v1/search?q=SABB%2C%20Linie%2C%20Apeldoorn%2C%20Nederland&key=AIzaSyBFDBnD28nTgFd9IY-yPKIMfKrGEAL1Nz0";
        var iframe = document.querySelector("#maps-iframe");
        var loadingElement = document.createElement("DIV");
        loadingElement.classList.add("loading");

        parentElement.removeChild(this);
        parentElement.appendChild(loadingElement);

        iframe.onload = function (parentElement, loadingElement) {
            document.querySelector("#maps-cookie-consent").setAttribute("style", "display: none");
        };

        iframe.setAttribute("src", mapsSrcUrl);
    });
}

if (alertDiv) {
    alertDiv.scrollIntoView();
}

function toggleNavbar() {

    if (navBarCollapsed) {
        mainNavbar.classList.add("in");
        mainNavbarToggle.classList.remove("collapsed");
        navBarCollapsed = false;
    } else {
        mainNavbar.classList.remove("in");
        mainNavbarToggle.classList.add("collapsed");
        navBarCollapsed = true;
    }
}

},{}]},{},[1]);
